import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#B1D0E0",
    },
    secondary: {
      main: "#6998ab",
    },
    background: {
      default: "#1A374D",
      paper: "#304E62",
    },
  },
  typography: {
    h1: {
      fontFamily: "Space Mono",
    },
    h2: {
      fontFamily: "Space Mono",
      fontWeight: 500,
    },
    fontFamily: "Open Sans",
    h3: {
      fontFamily: "Space Mono",
      fontWeight: 500,
    },
    h4: {
      fontFamily: "Space Mono",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Space Mono",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Space Mono",
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 24,
      },
    },
  },
});

export default theme;
